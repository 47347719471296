<template>
    <div class="ticket">
        <ModalSatisfaction v-if="modalShow"></ModalSatisfaction>
        <div class="pageHeader basketHeader">
            <div class="contentHeader basketHeaderContent">
                <img src="@/assets/img/back.png" @click="redirect">
                <span>{{$t('ticketName')}}</span>
            </div>

        </div>
        <div v-if="nbrProducts < 1" class="noProducts" >{{$t('errorEmptyTicket')}}</div>
        <div v-else class="cartProducts" style="padding-bottom: 120px;padding-top: 50px;">
            <div v-for="cartProduct in ticket.products" :key="cartProduct.id" class="cardContainer">
                <div class="productCard cardTemplateCartProduct row no-gutters">
                    <div v-if="cartProduct.qtyLocked" class="scannerTag">
                        <img src="@/assets/img/qrcode.png" alt="qrcode" class="qrcode_icon">
                    </div>
                    <div class="col-3 col-md-2 align-self-center" style="float:left;">
                        <img style="border:0" class="img-thumbnail imgProductCart" :src="img_url+cartProduct.product.image" />
                    </div>
                    <div class="product col-9 col-md-10 row no-gutters">
                        <div  v-if="getLang() == 'fr'">
                            <div class="nameProductCart col-12 align-self-center no-gutters">{{cartProduct.product.nameFr}}</div>
                            <div class="categoryProductCart col-12 align-self-center no-gutters">{{cartProduct.product.category.nameFr}} </div>
                        </div>
                        <div v-else>
                            <div class="nameProductCart col-12 align-self-center no-gutters">{{cartProduct.product.nameEn}}</div>
                            <div class="categoryProductCart col-12 align-self-center no-gutters">{{cartProduct.product.category.nameEn}} </div>
                        </div>
                        <div class="col-12 row align-self-end no-gutters">
                            <div class="col-4 col-md-2 no-gutters">
                                <p class="priceProduct">{{formatPrice(cartProduct.product.price.toFixed(2))}}</p>
                            </div>
                            <div class="col-6 col-md-7 row no-gutters ml-auto">
                                <div class="linkToCart ml-auto">
                                    <span class="qtyProduct">x{{cartProduct.qty}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row footer">
            <div class="amountContainer">
                <div class="amount">
                    {{$t('total')}} <strong v-if="nbrProducts>0">{{formatPrice(ticket.totalAmount.toFixed(2))}}</strong>
                    <strong v-else>{{formatPrice(0.00)}}</strong>
                </div>
            </div>
            <div class="footerActions ticketFooter">
                <img class="qrCode" v-b-modal.ticketModal :src="qrCodeImg"/>
                <b-modal id="ticketModal" hide-footer centered :title="$t('ticketQRCodeTitle')">
                    <div class="modal-body">
                        <img class="bigQrCode" v-b-modal.ticketModal :src="qrCodeImg"/>
                    </div>
                </b-modal>
                <div>
                    <button v-if="!$route.query.ticketId" @click="redirect" class="btn btn-active" >{{$t('backStart')}}</button>
                    <a v-else class="btn btn-active" href="#">{{$t('createAccount')}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import ModalSatisfaction from "@/components/Common/ModalSatisfaction";
    var qrcodeGenerator = require('qrcode-generator')
    var Utils = require("@/assets/js/Utils");
    var Basket = require("@/assets/js/Basket");
    var Stats = require("@/assets/js/Stats");

    export default {
        name: 'Ticket',
        components: {ModalSatisfaction},
        props: {
            'paymentSuccess' : String
        },
        data(){
            return{
                img_url : null,
                ticket : {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                qrCodeImg :'',
                fromRoute : null,
                modalShow: false
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
        })
        },
        created(){
            var config = Utils.getConfig();
            this.img_url = config.url_img;
            var qr = qrcodeGenerator(0, 'L');

            if(isNotNull(this.$route.query.ticketId)){
                var qrData = this.$route.query.ticketId;
                var demoId = this.$route.query.demoId;
                var univers = this.$route.query.univers;
                let self = this;
                Basket.getTicket(this.$route.query.ticketId, function (ticket) {
                    self.ticket = ticket;
                    self.ticket.products = ticket.basketProducts;
                    qrData += "__" + ticket.customer.firstName;
                    qr.addData(qrData);
                    qr.make();
                    self.qrCodeImg = qr.createDataURL();

                    var parameters = {
                        complexIdCustomer: ticket.customer.complexId,
                        complexIdDemonstration: demoId,
                        typeId: univers,
                        nbTicketAccessFromMail: 1
                    }
                    Stats.setStats(parameters);
                })
            }else{
                var encodedTicketId = localStorage.getItem("DKC_encodedTicketId");
                var firstname = localStorage.getItem("DKC_firstname");
                if(isNotNull(encodedTicketId) && isNotNull(firstname)){
                    qr.addData(encodedTicketId + "__" + firstname);
                    qr.make();
                    this.qrCodeImg = qr.createDataURL();
                }
                this.ticket = JSON.parse(localStorage.getItem("DKC_ticket"));
                Stats.setStats({nbTicketAccess: 1});

                if(!Utils.getLocalSatisfaction("FASHION")){
                    this.modalShow = true;
                }
            }
        },
        methods:{
            handleBack (fallback) {
                if (!this.fromRoute) {
                    this.$router.push(localStorage.getItem("DKC_base") + fallback);
                } else {
                    window.location.replace(window.location.origin + localStorage.getItem("DKC_base") + this.fromRoute.path);
                }

            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            redirect(){
                window.location.replace(window.location.origin + localStorage.getItem("DKC_base") + '/');
            },
            getLang(){
                return this.$root.$i18n.locale;
            }
        },
        computed:{
            nbrProducts : function() {
                var count = 0;
                if(this.ticket.products){
                    this.ticket.products.forEach(function(cartProduct) {
                        count+=cartProduct.qty;
                    });
                    return count
                }
            }
        }
    }
</script>