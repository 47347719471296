<template>
    <div id="modalSatisfaction" class="row">
        <b-modal id="modalSatisfaction"
                 ref="modalSatisfaction"
                 centered
                 v-model="modalShow"
                 :title="title">

            <image-rating :src="require('../../assets/img/star.png')" :show-rating="false" v-model="rating" class="justify-content-center"></image-rating>

            <div slot="modal-footer" class="modal-footer-custom">
                <b-button class="float-right" variant="success" @click="handleOk">{{modalYes}}</b-button>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import {ImageRating} from 'vue-rate-it';
    var Stats = require("@/assets/js/Stats");
    var Utils = require("@/assets/js/Utils");

    export default {
        name: "ModalSatisfaction",
        components: {
            ImageRating
        },
        data () {
            return {
                modalYes: this.$t('satisfactionYesButton'),
                modalShow: true,
                rating: 0,
                title: this.$t('satisfactionMessage')
            }
        },
        methods: {
            handleOk () {
                let type = parseInt(localStorage.getItem("DKC_univers"));
                switch(type){
                    case 1:
                        Utils.setLocalSatisfaction("FASHION");
                        break;
                    case 2:
                        Utils.setLocalSatisfaction("FOOD");
                        break;
                    case 3:
                        Utils.setLocalSatisfaction("FUEL");
                        break;
                }
                Stats.setStats({satisfaction: this.rating, nbRating: 1});
                this.$refs.modalSatisfaction.hide();
            }
        }
    }
</script>

<style scoped>

</style>